import axios from "axios";
import AuthService from "./AuthService";



//GET
const performRequest = (url: string, finishedLoading: any, setFinishedLoading: Function, setData: Function, name: string, navigate: Function) => {
    return new Promise((resolve, reject) => {
        AuthService.updateToken(() => {
            axios.get(`${process.env.https://crm.dah.test.beg-verwaltung.de}${url}`, { headers: { Authorization: `Bearer ${AuthService.getToken()}` } })
                .then(response => {
                    if (response.data.status === undefined){
                        if (response.status === 200 || response.status === 204) {
                            if (response.status === 200) setData(response.data);
                            const tempObject = finishedLoading;
                            tempObject[name] = true;
                            setFinishedLoading({ ...tempObject, [name]: true })
                            resolve(response);
                            return;
                        } else {
                            navigate('/error/' + response.status)
                            return;
                        }
                    }
                    if (response.data.status === "OK") {
                        setData(response.data.body)
                    } else if (response.data.status === "NOT_FOUND") {
                        navigate('/notmembererror')
                        return;
                    } else if (response.data.status === "FORBIDDEN") {
                        navigate('/error/403')
                        return;
                    }
                    console.log('Response: ' + name, response.data);
                    
                    const tempObject = finishedLoading;
                    tempObject[name] = true;
                    setFinishedLoading({ ...tempObject, [name]: true })
                    resolve(response);
                }).catch(error => {
                    console.error('Error:', error);
                    navigate(`/error/${error.response.status}`)
                    return;
                });
        });
    });
}

const performAsyncRequest = async (url: string, finishedLoading: any, setFinishedLoading: Function, setData: Function, name: string, navigate: Function) => {
    return await performRequest(url, finishedLoading, setFinishedLoading, setData, name, navigate);
}

const performAnonymousRequest = (url: string, finishedLoading: any, setFinishedLoading: Function, setData: Function, name: string, navigate: Function) => {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.https://crm.dah.test.beg-verwaltung.de}${url}`)
                .then(response => {
                    if (response.data.status === undefined){
                        if (response.status === 200) {
                            setData(response.data)
                        }
                    }
                    if (response.data.status === "OK") {
                        setData(response.data.body)
                    } else if (response.data.status === "NOT_FOUND") {
                        navigate('/notmembererror')
                        return;
                    } else if (response.data.status === "FORBIDDEN") {
                        navigate('/error/403')
                        return;
                    }
                    console.log('Response: ' + name, response.data);
                    
                    const tempObject = finishedLoading;
                    tempObject[name] = true;
                    setFinishedLoading({ ...tempObject, [name]: true })
                    resolve(response);
                }).catch(error => {
                    console.error('Error:', error);
                    navigate(`/error/${error.response.status}`)
                    return;
                });
    });
}

//PUT
const performPutRequest = (url: string, navigate: Function, body: Object) => {
    return new Promise((resolve, reject) => {
        AuthService.updateToken(() => {
            axios.put(`${process.env.https://crm.dah.test.beg-verwaltung.de}${url}`, body, { headers: { Authorization: `Bearer ${AuthService.getToken()}` }})
                .then(response => {
                    console.log('Response: ' + response.data);
                    resolve(response);
                }).catch(error => {
                    console.error('Error:', error);
                    navigate(`/error/${error.response.status}`)
                    return;
                });
        });
    });
}

const performAsyncPutRequest = async (url: string, navigate: Function, body: Object) => {
    return await performPutRequest(url, navigate, body);
}

//POST
const performPostRequest = (url: string, navigate: Function, body: Object) => {
    return new Promise((resolve, reject) => {
        AuthService.updateToken(() => {
            axios.post(`${process.env.https://crm.dah.test.beg-verwaltung.de}${url}`, body, { headers: { Authorization: `Bearer ${AuthService.getToken()}` }})
                .then(response => {
                    console.log('Response: ' + response.data);
                    resolve(response);
                }).catch(error => {
                    console.error('Error:', error);
                    navigate(`/error/${error.response.status}`)
                    return;
                });
        });
    });
}

const performAsyncPostRequest = async (url: string, navigate: Function, body: Object) => {
    return await performPostRequest(url, navigate, body);
}

//  CONFIG REQUEST
//


const getConfig = (setConfig: Function) => {
    axios.get(`${process.env.https://crm.dah.test.beg-verwaltung.de}/rest/services/beg_ClientConfigService/getConfig`)
        .then(response => {
            setConfig(response.data.body);
            document.title = "Mitgliederportal " + response.data.body.cooperativeName
        }).catch(error => {
            console.error('Error:', error);
            //if path is already serviceunavailable nothing happens
            //if (window.location.href.split("/")[window.location.href.split("/").length - 2] !== "serviceunavailable"){
            console.log(window.location.pathname)
            if (!window.location.pathname.startsWith("/serviceunavailable") && !window.location.pathname.startsWith("/health")){
                window.location.href = "/serviceunavailable/" + encodeURIComponent(window.location.pathname) ;
            }
        });
}

const downloadAttachment = (fileref: string, name: string) => {
    AuthService.updateToken(() => {
        axios({
            url: `${process.env.https://crm.dah.test.beg-verwaltung.de}/rest/files?fileRef=${encodeURIComponent(fileref)}`,
            headers: { Authorization: `Bearer ${AuthService.getToken()}` },
            method: 'GET',
            responseType: 'blob'
        }).then((response) => {
            //https://stackoverflow.com/questions/41938718/how-to-download-files-using-axios
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `${name}.pdf`);
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
            
    });
}

const uploadLoanContract = async (file: File |null, taskId: string | undefined) => {
    if(!file) {
      alert('Please choose a file to upload.');
      return;
    }

    if(!taskId) alert('Invalid taskId');

    const formData = new FormData();
    formData.append('file', file);
    const response = await fetch(`${process.env.https://crm.dah.test.beg-verwaltung.de}/loan/tasks/${taskId}/upload-contract`, {
      method: 'POST',
      headers: { Authorization: `Bearer ${AuthService.getToken()}` },
      body: formData
    });

    if(response.status !== 200 && response.status !== 204 ) alert("Upload went wrong");
    return response.body;
  }

const DataService = {
    performRequest,
    performAsyncRequest,
    performAnonymousRequest,
    performPutRequest,
    performAsyncPutRequest,
    performPostRequest,
    performAsyncPostRequest,
    getConfig,
    downloadAttachment,
    uploadLoanContract
};

export default DataService;