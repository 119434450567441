import axios from 'axios';
import { TaskDto } from '../types';
import AuthService from "./AuthService";

class TaskService {
    static async complete(id: string, variables: {}) {
        await AuthService.updateToken(null);
        return axios.post(`${process.env.https://crm.dah.test.beg-verwaltung.de}/loan/tasks/${id}/complete`,variables, { headers: { Authorization: `Bearer ${AuthService.getToken()}`}} )
        .then((response) => {
            if(response.status === 200) return response.data
        });
    }

    static async getTaskList(): Promise<TaskDto[]>{
        await AuthService.updateToken(null);
        return axios.get(`${process.env.https://crm.dah.test.beg-verwaltung.de}/loan/tasks/mine`, { headers: { Authorization: `Bearer ${AuthService.getToken()}` } })
        .then((response) => {
            if(response.status === 200) return response.data
        });
    }

    static async getTaskById(taskId: string): Promise<TaskDto>{
        await AuthService.updateToken(null);
        return axios.get(`${process.env.https://crm.dah.test.beg-verwaltung.de}/loan/tasks/${taskId}`, { headers: { Authorization: `Bearer ${AuthService.getToken()}` } })
        .then((response) => {
            if(response.status === 200) return response.data
        });
    }
}

export default TaskService;

